@import '~antd/dist/antd.css';
@import './iconfont/iconfont.css';
/* html,body,#root{min-width: 1400px;} */
.clearfix:after{content:"";display:block;clear:both;}
.clearfix{zoom:1;}
.logo {
	height: 32px;
	margin: 16px;
	overflow: hidden;
	/* background-image: url(/Public/images/adminlogo.png); */
	background-position: left center;
	background-repeat: no-repeat;
	background-size: auto 22px;
	color: #fff;
	line-height: 32px;
	font-size: 19px;
	text-align: center;
}
.ant-layout-sider-collapsed .logo{
	background-position: 8px center;
}
.site-layout .site-layout-background {
  background: #fff;
}
.loginpost{
	width: 315px;
	height: 300px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin:auto;
	background-color: #fff;
	padding: 25px;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.download-table-xls-button{
	display: none;
}
.ant-upload.ant-upload-drag{
	height: auto;
}
.grinfo{
	width: 100%;
	text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
	border-spacing: 0;
	border: 1px solid #f0f0f0;
	border-right: 0;
	border-bottom: 0;
	margin-bottom: 24px;
}
.grinfo.noborder{border:0}
.grinfo.noborder th,.grinfo.noborder td{border:0;padding: 0;}
.grinfo .th{
	color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #fafafa;
	border-bottom: 1px solid #f0f0f0;
	border-right: 1px solid #f0f0f0;
    -webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
	position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
}
.grinfo td.title{
	color:#1890ff
}
.grinfo td{
	border-right: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
    -webkit-transition: background 0.3s;
	transition: background 0.3s;
	padding: 16px;
	color: #666;
}
.grinfo td .time{
	font-size: 12px;
}

/* 手机版 */
.mb-header{
	padding: 0 10px;
	height: 60px;
	background-color: #1890ff;
	position: relative;
	/* margin-bottom: 15px; */
}
.mb-header .title{
	width: 100%;
	height: 100%;
	line-height: 60px;
	font-size: 16px;
	color: #fff;
	text-align: center;
	margin-bottom: 0;
	position: absolute;
	top: 0;
	left: 0;
}
.mb-header .back{
	position: absolute;
	height: 60px;
	
	left: 10px;
	z-index: 99;
}
.mb-header .back a{
	display: block;
	width: 100%;
	height: 100%;
	color: #fff;
	font-size: 20px;
	line-height: 60px;
}
.mb-header .mb-menu{
	font-size: 25px;
	color: #fff;
	height: 100%;
	line-height: 60px;;
	position: absolute;
	right: 10px;
	top: 0px;
	z-index: 99;
}
.mb-header .menulist{
	width: 100%;
	position: absolute;
	top: 60px;
	left: 0px;
	z-index: 99;
	background-color: #eee;
	overflow: hidden;
	height: 0;
	transition: all 0.3s;
}
.mb-header .menulist.hover{height: 50vh;overflow: auto;}
.mb-header .menulist ul{
	width: 100%;
	padding: 0;
}
.mb-header .menulist ul li{
	width: 100%;
	height: 35px;
	box-sizing: border-box;
	padding: 0 10px;
	border-bottom: 1px solid #ddd;
	list-style: none;
	line-height: 35px;
}
.mb-header .menulist ul li.hover{
	background-color: #2679c5;
}
.mb-header .menulist ul li a{
	line-height: 35px;
	display: block;
	width: 100%;
	height: 100%;
	color: #666;
}
.mb-header .menulist ul li.hover a{
	color: #fff;
}
div.ant-typography{
	display: inline-block;
	margin-right: 15px;
}
div.ant-typography, .ant-typography p{
	margin-bottom: 0;
}

.ls .ant-table-thead > tr > th, .ls .ant-table-tbody > tr > td, .ls .ant-table tfoot > tr > th, .ls .ant-table tfoot > tr > td{
	padding: 12px 16px;
}
.qrcode-button{
	position: absolute;
	top: 0;
	right: 0;
	width: 35px;
	height: 35px;
	background-color: #282c34;
  }

  .mail{
	  width: 100%;
	  max-width: 750px;
	  height: 100vh;
	  margin: 0 auto;
	  background-color: #f5f5f5;
  }

  .table_title{
	  width: 100%;
	  margin-bottom: 15px;
	  display: flex;
	  justify-content: flex-end;
  }
  .table_title .l{
	display: flex;
	justify-content: flex-start;
  }
  .table_title .r{
	  display: flex;
	  justify-content: flex-end;
}
.fc td{color: #ddd;}
.delfile{
	color: #f00;
	margin-left: 15px;
}
.sellist{
	width: 100%;
	margin-bottom: 15px;
}
.page{
	text-align: center;
	width: 100%;
	padding: 25px 0;
}

.xmTop{
	width: 100%;
	box-sizing: border-box;
	padding-bottom: 20px;
	border-bottom: 5px solid #f2f2f2;
}
.xmTop > .p1{
	width: 100%;
	font-size: 18px;
	color: #000;
	font-weight: bold;
	margin-bottom: 15px;
}
.xmTop > .p2{
	width: 100%;
}
.xmTop > .p2 .item{
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
}
.xmTop > .p2 .item .l{
	width: 35px;
	margin-right: 15px;
}
.xmTop > .p2 .item .l .iconfont{
	font-size: 22px!important;
	color: #fff;
}
.xmTop > .p2 .item .r .p1{
	font-size: 12px;
	color: rgba(0, 0, 0, 0.25);
}
.xmTop > .p2 .item .r .p2{
	font-size: 14px;
	color: rgba(0, 0, 0, 0.8);
}
.fjlist{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
.fjlist.fjlist2{
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}
.fjlist > .item{
	width: calc((100% - 120px)/2);
}
.fjlist.fjlist2 > .item{
	width: 100%;
}
.fjlist > .item  .fjtitle{
	width: 100%;
	box-sizing: border-box;
	padding: 0 25px;
	font-size: 16px;
	color: #000;
}
.fjlist > .item  .list > .item{
	width: 100%;
	/* height: 35px; */
	box-sizing: border-box;
	padding: 10px 25px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
.fjlist > .item  .list > .item:hover{
	background-color: rgb(242,242,242);
}
.fjlist > .item  .list > .item .l{
	width: calc(100% - 140px);
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
}
.fjlist > .item  .list > .item .l .pic{
	margin-right: 10px;
}
.fjlist > .item  .list > .item .l .pic .iconfont{
	font-size: 25px!important;
}
.fjlist > .item  .list > .item .l .info{
	width: calc(100% - 35px);
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-direction: column;
}
.fjlist > .item  .list > .item .l .info .p1{
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 12px;
	color: #000;
}
.fjlist > .item  .list > .item .l .info .p2{
	width: 100%;
	font-size: 12px;
	color: #333;
}
.fjlist > .item  .list > .item .r{
	width: 155px;
}
.fjlist > .item  .list > .item .r.r1{
	font-size: 12px;
	color: #333;
	text-align: right;
}
.fjlist > .item  .list > .item .r.r2{
	font-size: 25px;
	color: rgb(90,90,104);
	justify-content: flex-end;
	align-items: center;
	display: none;
}
.fjlist > .item  .list > .item .r.r2 .del{
	color: #f00;
}
.fjlist > .item  .list > .item .r.r2 .anticon{
	cursor: pointer;
}
.fjlist > .item  .list > .item:hover .r.r1{
	display: none;
}
.fjlist > .item  .list > .item:hover .r.r2{
	display: flex;
}

.tuozhuai{
	width: 100%;
	height: calc(100% - 55px);
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}
.tuozhuai .itemlist{
	width: calc((100% - 45px)/4);
	
}
.tuozhuai .itemlist .list{
	width: 100%;
	padding: 15px 0;
	padding-bottom: 0;
	height: calc(100% - 40px);
	border-radius: 5px;
	overflow-y: auto;
	display: inline-block;
}
.tuozhuai .itemlist:nth-child(1) .list{
	background-color: rgb(253,241,242);
}
.tuozhuai .itemlist:nth-child(2) .list{
	background-color: rgb(244,250,255);
}
.tuozhuai .itemlist:nth-child(3) .list{
	background-color: rgb(248,248,248);
}
.tuozhuai .itemlist:nth-child(4) .list{
	background-color: rgb(247,251,241);
}
.tuozhuai .itemlist .list .item{
	width: calc(100% - 30px);
	margin: 0 15px;
	padding: 15px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 5px;
	margin-bottom: 15px;
}
.tuozhuai .itemlist .list .item .p1{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tuozhuai .itemlist .list .item .p1 .l{
	font-size: 16px;
	color: #1890FF;
	width: 210px;
}
.tuozhuai .itemlist .list .item .p1 .r{
	flex-shrink: 0;
}
.tuozhuai .itemlist .list .item .p1 .r .ok{
	padding: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(242,242,242);
	font-size: 10px;
	color: #7F7F7F;
	border-radius: 15px;
	cursor: pointer;
}
.tuozhuai .itemlist .list .item .p1 .r .jj{
	color: #D9001B;
}
.tuozhuai .itemlist .list .item .p2{
	width: 100%;
	font-size: 12px;
	color: #555;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.tuozhuai .itemlist .list .item .p3{
	width: 100%;
}
.tuozhuai .itemlist .list .item .p3 .bqitem{
	padding: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(242,242,242);
	font-size: 10px;
	color: #7F7F7F;
	border-radius: 15px;
	position: relative;
}
.tuozhuai .itemlist .list .item .p3 .bqitem.hover::after{
	content: '\e8e5';
	width: 100%;
	height: 100%;
	background-color: rgba(75, 121, 2, 0.5);
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: #fff;
}
.tuozhuai .itemlist .list .item .p4{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tuozhuai .itemlist .list .item .p4 .l{
	font-size: 12px;
	flex-shrink: 0;
}
.tuozhuai .itemlist .list .item .p4 .l .biao{
	margin-right: 5px;
}
.tuozhuai .itemlist .list .item .p4 .l .cs{
	color: #D9001B;
	margin-left: 15px;
}
.tuozhuai .itemlist .list .item .p4 .l.l1{
	color: #D9001B;
}
.tuozhuai .itemlist .list .item .p4 .l.l1{
	color:#1890FF;
}
.tuozhuai .itemlist .list .item .p4 .l.l1{
	color: #aaa;
}
.tuozhuai .itemlist .list .item .p4 .l.l1{
	color: #70B603;
}
.tuozhuai .itemlist .list .item .p4 .r .txitem{
	width: 25px;
	height: 25px;
	position: relative;
	border: 0;
}
.tuozhuai .itemlist .list .item .p4 .r .txitem.hover::after{
	content: '\e8e5';
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: rgba(75, 121, 2, 1);;
}
.tuozhuai .itemlist .title{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}
.tuozhuai .itemlist .title .l{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.tuozhuai .itemlist .title .l .p1{
	font-size: 16px;
	color: #333;
	margin-right: 15px;
}
.tuozhuai .itemlist .title .l .p2{
	font-size: 16px;
}
.tuozhuai .itemlist .title .l .p2.p2_1{
	color: #D9001B;
}
.tuozhuai .itemlist .title .l .p2.p2_2{
	color: #1890FF;
}
.tuozhuai .itemlist .title .l .p2.p2_3{
	color: #aaa;
}
.tuozhuai .itemlist .title .l .p2.p2_4{
	color: #70B603;
}
.tuozhuai .itemlist .title .r .addgzx{
	cursor: pointer;
}

.titletype.t1{
	color: #D9001B;
}
.titletype.t2{
	color: #1890FF;
}
.titletype.t3{
	color: #aaa;
}
.titletype.t4{
	color: #70B603;
}

.fujian{
	width: 100%;
	padding-top: 15px;
}
.fujian .item{
	width: 100%;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #1890FF;
}
.fujian .item:last-child{
	/* margin-bottom: 0; */
}
.fujian .item .l{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	color: #1890FF;
}
.fujian .item .l a{
	color: inherit;
	margin-left: 3px;
}

.ulist{
	width: 100%;
	padding-top: 15px;
}
.ulist .item{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}
.ulist .item:last-child{
	margin-bottom: 0;
}
.ulist .item .l{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.ulist .item .l .pic{
	margin-right: 15px;
}
.ulist .item .l .name{
	width: 65px;
	font-size: 16px;
	color: #1890FF;
	margin-right: 15px;
}

.gzxtop{
	width: 100%;
	/* height: 37px; */
	margin-bottom: 15px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border:1px solid #ddd;
	border-radius: 5px;
	padding: 6px;
}
.gzxtop .l{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.gzxtop .l .p1{
	width: 46px;
	height: 26px;
	border-radius: 5px;
	background-color: #1890FF;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: #fff;
	margin-right: 10px;
}
.gzxtop .l .p2{
	font-size: 16px;
	color: #333;
	margin-right: 10px;
}
.gzxtop .l .p3{
	font-size: 16px;
	color: #1890FF;
}
.gzxtop .r{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.gzxtop .r .item{
	margin-left: 45px;
	font-size: 14px;
	color: #7f7f7f;
	cursor: pointer;
}
.gzxtop .r .item:first-child{
	margin-left: 0;
}
.gzxtop .r .item .anticon{
	margin-right: 5px;
}

.gzxinfo{
	width: 100%;
}
.gzxinfo .p1{
	width: 100%;
	font-size: 20px;
	color: #1890ff;
	margin-bottom: 10px;
}
.gzxinfo .p2{
	width: 100%;
	font-size: 14px;
	color: #555;
	margin-bottom: 10px;
}
.gzxinfo .p3{
	width: 100%;
	font-size: 14px;
	color: #7f7f7f;
	margin-bottom: 10px;
}
.gzxinfo .p4{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
}
.gzxinfo .p4 .l{
	margin-right: 30px;
}
.gzxinfo .p4 .l .bqitem{
	padding: 2px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(242,242,242);
	font-size: 14px;
	color: #7F7F7F;
	border-radius: 15px;
	position: relative;
}
.gzxinfo .p4 .l .bqitem.hover::after{
	content: '\e8e5';
	width: 100%;
	height: 100%;
	background-color: rgba(75, 121, 2, 0.5);
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: #fff;
}

.gzxinfo .p4 .r .txitem{
	width: 32px;
	height: 32px;
	position: relative;
	border: 0;
}
.gzxinfo .p4 .r .txitem.hover::after{
	content: '\e8e5';
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: rgba(75, 121, 2, 1);;
}
.gzxinfo .p5{
	width: 100%;
	min-height: 100px;
	font-size: 16px;
	color: #000;
}

.work{
	width: 100%;
	box-sizing: border-box;
	padding: 25px;
	margin-bottom: 15px;
}
.work.w1{
	background-color: rgb(246,253,248);
}
.work.w2{
	background-color: rgb(248,248,248);
}
.work .item{
	width: 100%;
}
.work .item .p1{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}
.work .item .a1{
	font-size: 14px;
	color: rgba(0, 0, 0, 0.65);
}
.work .item .a1 .pic{
	color: #70B603;
}
.work .item .a2{
	font-size: 14px;
	color: rgba(170, 170, 170, 0.65)
}
.work .item .a3{
	font-size: 14px;
	color: rgba(170, 170, 170, 0.65)
}
.work .item .p2{
	width: 100%;
	margin-bottom: 10px;
}
.xmviewgzx{
	height: calc(100% - 109px);
}
.xmviewgzx .ant-tabs-content-holder {
	height: max-content;
}
.xmviewgzx .ant-tabs-content-holder .ant-tabs-content{
	height: 100%;
}