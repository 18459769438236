@font-face {
  font-family: "iconfont"; /* Project id 3476670 */
  src: url('iconfont.woff2?t=1656380997558') format('woff2'),
       url('iconfont.woff?t=1656380997558') format('woff'),
       url('iconfont.ttf?t=1656380997558') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xiangmu:before {
  content: "\e611";
}

.icon-renwu:before {
  content: "\e64a";
}

.icon-yulan:before {
  content: "\e642";
}

.icon-caozuorizhi:before {
  content: "\e603";
}

.icon-xwtubiaoku-13:before {
  content: "\e61a";
}

.icon-xwtubiaoku-16:before {
  content: "\e61d";
}

.icon-shijian1:before {
  content: "\e6c8";
}

.icon-excel:before {
  content: "\e665";
}

.icon-image:before {
  content: "\e667";
}

.icon-pdf:before {
  content: "\e66a";
}

.icon-ppt:before {
  content: "\e66b";
}

.icon-psd:before {
  content: "\e66c";
}

.icon-txt:before {
  content: "\e66d";
}

.icon-word:before {
  content: "\e66e";
}

.icon-zip:before {
  content: "\e8b7";
}

.icon-user:before {
  content: "\e7ae";
}

.icon-shengguangbaojing:before {
  content: "\eb25";
}

.icon-zhanghu:before {
  content: "\eb42";
}

.icon-yonghuguanli:before {
  content: "\e60f";
}

.icon-yonghuming:before {
  content: "\e60a";
}

.icon-renwudongtai:before {
  content: "\eb21";
}

.icon-calendar:before {
  content: "\e7d3";
}

.icon-renwuyiwancheng:before {
  content: "\eb16";
}

.icon-renwuxiangqing:before {
  content: "\eb17";
}

.icon-renwuxinzeng:before {
  content: "\eb18";
}

.icon-renwuweikaishi:before {
  content: "\eb19";
}

.icon-xiugaixiang:before {
  content: "\eb1a";
}

.icon-renwujinhangzhong:before {
  content: "\eb1b";
}

.icon-wenzhang:before {
  content: "\eb2e";
}

.icon-shijian:before {
  content: "\e67b";
}

.icon-shanchu2:before {
  content: "\e600";
}

.icon-xiala:before {
  content: "\e6b9";
}

.icon-shuaxin:before {
  content: "\e6b2";
}

.icon-zhengquewancheng:before {
  content: "\e8e5";
}

.icon-zengjia:before {
  content: "\e889";
}

.icon-rizhiguanli:before {
  content: "\e62f";
}

.icon-shanchu:before {
  content: "\e602";
}

